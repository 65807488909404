import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  FC,
  Dispatch,
  SetStateAction,
} from 'react';
import { getEmbedSettings, setEmbedSettings } from '../../../api';
import { NotificationsCTX } from '../../../contexts/Notification';
import Button from '../Button';
import { getDefaultDomain, getUrlList } from '../../../core/helper';
import { Dropdown } from '../Form';
import { SketchPicker } from 'react-color';
import { useIntl } from 'react-intl';
import messages from './messages';
import { Content, User } from '../../../core/types';

type ColorPickerProps = {
  color: string;
  onChange?: (e: any) => void;
};

type Props = {
  data: Content;
  embedCode: string;
  setEmbedCode: Dispatch<SetStateAction<string>>;
  widgetUrl: string;
  setWidgetUrl: Dispatch<SetStateAction<string>>;
  finalCode: string;
  setFinalCode: Dispatch<SetStateAction<string>>;
  width: string;
  setWidth: Dispatch<SetStateAction<string>>;
  height: string;
  setHeight: Dispatch<SetStateAction<string>>;
  buttons?: boolean;
  setOpen?: (arg: boolean) => void;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  url?: string;
  modal?: boolean;
  selectedIdxPrew?: number;
  user?: User;
  flyingQs: string;
  setFlyingQs: Dispatch<SetStateAction<string>>;
};

const convertRgbString = (val: any) => {
  const rgbString = `rgba(${val.r}, ${val.g}, ${val.b}, ${val.a})`;
  return rgbString;
};

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

const ColorPicker: FC<ColorPickerProps> = ({ color, onChange }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((s) => !s);
  };
  return (
    <>
      {show ? (
        <div style={{ position: 'absolute', zIndex: '20' }}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={toggleShow}
          />
          <SketchPicker color={`${color}`} onChange={onChange} />
        </div>
      ) : null}
      <div
        onClick={toggleShow}
        className="rounded-md"
        style={{
          backgroundColor: `${color}`,
          height: 38,
          width: 50,
          borderStyle: 'solid',
        }}
      />
    </>
  );
};

const EmbedSettings: FC<Props> = ({
  data,
  embedCode,
  setEmbedCode,
  widgetUrl,
  setWidgetUrl,
  finalCode,
  setFinalCode,
  width,
  setWidth,
  height,
  setHeight,
  buttons,
  setOpen,
  type,
  setType,
  url,
  modal,
  selectedIdxPrew,
  user,
  setFlyingQs,
}) => {
  const [disclaimer, setDisclaimer] = useState(true);
  const [alignment, setAlignment] = useState('center');
  const [vertical, setVertical] = useState('off');
  const [resultfit, setResultFit] = useState('sc');
  const [openLinks, setOpenLinks] = useState('_blank');
  const [lang, setLang] = useState('default');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [nocookie, setCookie] = useState(false);
  const [puid, setPUID] = useState('');
  const [custom_id, setCustom_id] = useState('');
  const [noAutoResize, setNoAutoResize] = useState(false);
  const [bgColor, setBgColor] = useState('rgba(0, 158, 235, 1)');
  const [textColor, setTextColor] = useState('rgba(243, 244, 246, 1)');
  const [bg, setBg] = useState('rgba(243, 244, 246, 1)');
  const [buttonIconColor, setButtonIconColor] = useState('#1E3D54');
  const [widgetIconColor, setWidgetIconColor] = useState('#1E3D54');
  const [text, setText] = useState('Start Now');
  const [title, setTitle] = useState("Let's choose together");
  const [desc, setDesc] = useState(
    'Not sure yet? Don’t worry! With a few simple questions we will guide you through the selection'
  );
  const [position, setPosition] = useState('bottom-right');
  // const [sidebarPosition, setSidebarPosition] = useState('');
  const [flyingCode, setFlyingCode] = useState('');
  const [saving, setSaving] = useState(false);
  const [link, setLink] = useState(url);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [positionIdx, setPositionIdx] = useState(5);
  // const [sbPositionIdx, setSbPositionIdx] = useState(0);
  const [buttonZIndex, setButtonZIndex] = useState('100');
  const [hideButton, setHideButton] = useState(false);

  const { error, success } = useContext(NotificationsCTX);

  const { formatMessage } = useIntl();

  const langOptions = useMemo(
    () => [
      {
        name: 'DeviceLanguage',
        value: 'default',
      },
      {
        name: 'English',
        value: 'en',
      },
      {
        name: 'Turkish',
        value: 'tr',
      },
      {
        name: 'Spanish',
        value: 'es',
      },
      {
        name: 'Italian',
        value: 'it',
      },
      {
        name: 'Portuguese',
        value: 'pt',
      },
      {
        name: 'Arabic',
        value: 'ar',
      },
      {
        name: 'Austrian',
        value: 'de-at',
      },
      {
        name: 'Algerian',
        value: 'ar-dz',
      },
      {
        name: 'French',
        value: 'fr',
      },
      {
        name: 'German',
        value: 'de',
      },
      {
        name: 'Romanian',
        value: 'ro',
      },
      {
        name: 'Dutch',
        value: 'nl',
      },
      {
        name: 'Hebrew',
        value: 'he',
      },
      {
        name: 'Thai',
        value: 'th',
      },
      {
        name: 'Hungarian',
        value: 'hu',
      },
      {
        name: 'Egyptian',
        value: 'ar-eg',
      },
      {
        name: 'Moroccan',
        value: 'ar-ma',
      },
    ],
    []
  );

  const tabs = useMemo(
    () => [
      { name: 'iFrame / WebView', current: type === 'iframe', value: 'iframe' },
      {
        name: formatMessage(messages.DirectLink),
        current: type === 'direct',
        value: 'direct',
      },
      {
        name: formatMessage(messages.FlyingWidget),
        current: type === 'flying',
        value: 'flying',
      },
    ],
    [formatMessage, type]
  );

  const removeEmptyLines = (str: string) =>
    str
      .split(/\r?\n/)
      .filter((line) => line.trim() !== '')
      .join('\n');

  useEffect(() => {
    setWidgetUrl(
      `https://${link}/widget/${data.public_id}?disclaimer=${
        disclaimer ? 'on' : 'off'
      }${alignment === 'center' ? '' : `&align=${alignment}`}${
        vertical === 'off' ? '' : `&vertical=${vertical}`
      }${resultfit !== 'sc' ? `&resultfit=${resultfit}` : ''}${
        lang === 'default' ? '' : `&loc=${lang}`
      }${utmSource ? `&utm_source=${utmSource}` : ''}${
        utmMedium ? `&utm_medium=${utmMedium}` : ''
      }${utmCampaign ? `&utm_campaign=${utmCampaign}` : ''}${
        puid ? `&puid=${puid}` : ''
      }${custom_id ? `&custom_id=${custom_id}` : ''}${
        nocookie ? `&cookie=off` : ''
      }${openLinks === '_blank' ? '' : `&openlinks=${openLinks}`}`
    );
  }, [
    data,
    disclaimer,
    lang,
    utmCampaign,
    utmSource,
    utmMedium,
    resultfit,
    puid,
    alignment,
    nocookie,
    openLinks,
    vertical,
    setWidgetUrl,
    custom_id,
    link,
  ]);

  useEffect(() => {
    setFlyingCode(
      `<div
      class="poltio-widget-flying"
      data-poltio-widget-content="${data.public_id}"
      data-poltio-widget-disclaimer=${disclaimer ? '"on"' : '"off"'}
      ${lang === 'default' ? '' : `data-poltio-widget-lang="${lang}"`}
      ${nocookie ? `data-poltio-widget-cookie="off"` : ''}
      ${openLinks !== '_blank' ? `data-poltio-widget-openlinks="${openLinks}"` : ''}
      ${resultfit !== 'sc' ? `data-poltio-widget-resultfit="${resultfit}"` : ''}
      ${puid ? `data-poltio-widget-puid=${puid}` : ''}
      ${custom_id ? `data-poltio-widget-custom_id=${custom_id}` : ''}
      ${utmSource ? `data-poltio-widget-utm_source=${utmSource}` : ''}
      ${utmMedium ? `data-poltio-widget-utm_medium=${utmMedium}` : ''}
      ${utmCampaign ? `data-poltio-widget-utm_campaign=${utmCampaign}` : ''}
      data-poltio-widget-bgcolor="${bg}"
      data-poltio-floating-bgcolor="${bgColor}"
      data-poltio-floating-textcolor="${textColor}"
      data-poltio-floating-icon-color="${buttonIconColor}"
      data-poltio-floating-widget-icon-color="${widgetIconColor}"
      data-poltio-floating-buttontext="${text}"
      data-poltio-floating-title="${title}"
      data-poltio-floating-desc="${desc}"
      data-poltio-floating-zindex="${buttonZIndex || '100'}"
      data-poltio-floating-position="${position}"
    ></div>
    <script src="https://platform.poltio.com/poltio.js" defer></script>
      `
    );
  }, [
    data,
    disclaimer,
    lang,
    utmCampaign,
    utmSource,
    utmMedium,
    resultfit,
    puid,
    alignment,
    nocookie,
    openLinks,
    vertical,
    setWidgetUrl,
    height,
    width,
    noAutoResize,
    custom_id,
    bgColor,
    textColor,
    text,
    title,
    desc,
    bg,
    buttonZIndex,
    position,
  ]);

  useEffect(() => {
    setFlyingQs(
      `?content=${data.public_id}&diclaimer=${disclaimer ? 'on' : 'off'}&loc=${lang}&resultfit=${resultfit !== 'sc' ? `${resultfit}` : ''}&widgetbg=${bg}&floatingbg=${bgColor}&floatingtext=${textColor}&floatingtitle=${title}&floatingdesc=${desc}&floatingbuttontext=${text}&floatingzindex=${buttonZIndex}&floatinghidebutton=${hideButton}&floatingposition=${position}&iconcolor=${buttonIconColor}&widgeticoncolor=${widgetIconColor}`
    );
  });

  useEffect(() => {
    setEmbedCode(
      `<iframe id="poltio-embed-${
        data.public_id
      }" class="poltio-embed" src="${widgetUrl}" loading="lazy" width="${width}" height="${height}" frameBorder="0"  allowFullScreen="allowfullscreen" scrolling="yes" title="${
        data.title
      }" ${
        noAutoResize ? `resize="no"` : ''
      } allow="clipboard-write"></iframe><script src="https://platform.poltio.com/widget.js" defer></script>`
    );
  }, [data, height, width, widgetUrl, setEmbedCode, noAutoResize]);

  useEffect(() => {
    setFinalCode(
      type === 'iframe'
        ? removeEmptyLines(embedCode)
        : type === 'direct'
          ? widgetUrl
          : removeEmptyLines(flyingCode)
    );
  }, [embedCode, setFinalCode, type, widgetUrl, flyingCode]);

  const urlList = getUrlList(user);

  const defaultCD = getDefaultDomain(user);

  const positionList = [
    { title: 'top-left' },
    { title: 'top-right' },
    { title: 'center-left' },
    { title: 'center-right' },
    { title: 'bottom-left' },
    { title: 'bottom-right' },
  ];

  useEffect(() => {
    !modal ? setLink(url) : setLink(defaultCD);
  }, [defaultCD, modal, url]);

  useEffect(() => {
    selectedIdxPrew
      ? setSelectedIdx(selectedIdxPrew)
      : setSelectedIdx(urlList.indexOf(link));
  }, [urlList, link, selectedIdx, selectedIdxPrew]);

  const copyEmbedCode = async () => {
    try {
      await navigator.clipboard.writeText(finalCode);
      success('Embed code copied.');
    } catch (e) {
      console.log(e);
      error('Error copying embed code!');
    }
  };

  const getData = useCallback(async () => {
    try {
      const { data: resp } = await getEmbedSettings('content');
      setWidth(resp.width || '100%');
      setHeight(resp.height || '800');
      setLang(resp.lang || 'default');
      setAlignment(resp.alignment || 'center');
      setResultFit(resp.resultfit || 'sc');
      setDisclaimer(
        typeof resp.disclaimer != 'undefined' ? resp.disclaimer : true
      );
      setUtmSource(resp.utmSource || '');
      setUtmMedium(resp.utmMedium || '');
      setUtmCampaign(resp.utmCampaign || '');
    } catch (e) {
      console.log(e);
      error('Error occured!');
    }
  }, [error, setHeight, setWidth]);

  useEffect(() => {
    getData();
  }, [getData]);

  const saveSettings = async () => {
    try {
      setSaving(true);
      await setEmbedSettings('content', {
        width,
        height,
        lang,
        alignment,
        disclaimer,
        utmSource,
        utmMedium,
        utmCampaign,
        nocookie,
      });
      success('Settings saved');
      setSaving(false);
    } catch (e) {
      console.log(e);
      error('Error occured saving settings!');
      setSaving(false);
    }
  };

  const handleTypeChange = (e: any) => {
    e.preventDefault();
    setType(e.target.value);
  };

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          // defaultValue={tabs.find((tab) => tab.current).name}
          defaultValue={tabs[0].name}
          onChange={handleTypeChange}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-poltio-blue- focus:outline-none focus:ring-poltio-blue- sm:text-sm"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                aria-current={tab.current ? 'page' : undefined}
                value={tab.value}
                onClick={(e) => handleTypeChange(e)}
                className={classNames(
                  tab.current
                    ? 'border-poltio-blue-500 text-poltio-blue-500'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      {type === 'flying' ? (
        <div>
          <div className="my-2">
            <label
              htmlFor="data-text"
              className="block text-sm font-medium text-gray-700"
            >
              {formatMessage(messages.Title)}
            </label>
            <input
              type="text"
              name="title"
              id="title"
              className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Floating Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label
              htmlFor="data-text"
              className="block text-sm font-medium text-gray-700"
            >
              {formatMessage(messages.Description)}
            </label>
            <textarea
              name="desc"
              id="desc"
              rows={2}
              className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Floating Description"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
          <div className="my-2">
            <label
              htmlFor="data-text"
              className="block text-sm font-medium text-gray-700"
            >
              {formatMessage(messages.ButtonText)}
            </label>
            <input
              type="text"
              name="text"
              id="text"
              className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Button Text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div className="flex w-full justify-between">
            <div className="my-2">
              <label
                htmlFor="data-text"
                className="block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.FloatingColor)}
              </label>
              <ColorPicker
                onChange={(e) => {
                  setBgColor(convertRgbString(e.rgb));
                }}
                color={bgColor}
              />
            </div>
            <div className="my-2">
              <label
                htmlFor="data-text"
                className="block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.FloatingTextColor)}
              </label>
              <ColorPicker
                onChange={(e) => {
                  setTextColor(convertRgbString(e.rgb));
                }}
                color={textColor}
              />
            </div>
            <div className="my-2">
              <label
                htmlFor="data-text"
                className="block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.SlideoverColor)}
              </label>
              <ColorPicker
                onChange={(e) => {
                  setBg(convertRgbString(e.rgb));
                }}
                color={bg}
              />
            </div>
          </div>
          <div className="flex w-full justify-between">
            <div className="my-2">
              <label
                htmlFor="button-icon-color"
                className="block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.ButtonIconColor)}
              </label>
              <ColorPicker
                onChange={(e) => {
                  setButtonIconColor(convertRgbString(e.rgb));
                }}
                color={buttonIconColor}
              />
            </div>
            <div className="my-2">
              <label
                htmlFor="widget-icon-color"
                className="block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.WidgetIconColor)}
              </label>
              <ColorPicker
                onChange={(e) => {
                  setWidgetIconColor(convertRgbString(e.rgb));
                }}
                color={widgetIconColor}
              />
            </div>
          </div>
          <div className="mt-2">
            <div className="flex items-center h-5 mt-2">
              <input
                id="hideButton"
                name="hideButton"
                type="checkbox"
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
                checked={hideButton}
                onChange={(e) => setHideButton(e.target.checked)}
              />
              <div className="ml-3 text-sm">
                <label htmlFor="comments" className="font-medium text-gray-700">
                  {formatMessage(messages.HideButton)}
                </label>
              </div>
            </div>
          </div>
          <div className="mt-2 w-1/2">
            <label
              htmlFor="custom_id"
              className="block text-sm font-medium text-gray-700"
            >
              {formatMessage(messages.ButtonPosition)}
            </label>
            <Dropdown
              data={positionList}
              selectedIdx={positionIdx}
              onChange={(i) => {
                setPositionIdx(i);
                setPosition(positionList[i].title);
              }}
            />
          </div>
          <div className="mt-2">
            <label
              htmlFor="buttonZIndex"
              className="block text-sm font-medium text-gray-700"
            >
              {formatMessage(messages.ButtonZIndex)}
            </label>
            <input
              type="text"
              name="buttonZIndex"
              id="buttonZIndex"
              className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="buttonZIndex"
              value={buttonZIndex}
              onChange={(e) => setButtonZIndex(e.target.value)}
            />
          </div>
        </div>
      ) : null}
      <div className="mb-2 mt-5">
        <label
          htmlFor="width"
          className="block text-sm font-medium text-gray-700"
        >
          {formatMessage(messages.Width)}
        </label>
        <input
          type="text"
          name="width"
          id="width"
          autoComplete="new-password"
          className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="iframe width"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
      </div>
      <div className="my-2">
        <label
          htmlFor="height"
          className="block text-sm font-medium text-gray-700"
        >
          {formatMessage(messages.Height)}
        </label>
        <input
          type="text"
          name="height"
          id="height"
          autoComplete="new-password"
          className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="iframe height"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>
      <div className="mt-2 flex items-start">
        <div className="flex items-center h-5">
          <input
            id="disclaimer"
            name="disclaimer"
            type="checkbox"
            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
            checked={disclaimer}
            onChange={(e) => setDisclaimer(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            {formatMessage(messages.DisplayDisclaimer)}
          </label>
        </div>
      </div>
      <div className="mt-2 flex items-start">
        <div className="flex items-center h-5">
          <input
            id="nocookie"
            name="nocookie"
            type="checkbox"
            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
            checked={nocookie}
            onChange={(e) => setCookie(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            {formatMessage(messages.DontUseCookie)}
          </label>
        </div>
      </div>
      <div className="mt-2 flex items-start">
        <div className="flex items-center h-5">
          <input
            id="noAutoResize"
            name="noAutoResize"
            type="checkbox"
            className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300 rounded"
            checked={noAutoResize}
            onChange={(e) => setNoAutoResize(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="noAutoResize" className="font-medium text-gray-700">
            {formatMessage(messages.DontUseResize)}
          </label>
        </div>
      </div>
      <div className="my-2 grid grid-cols-2">
        <fieldset className="mt-3 col-span-1">
          <div>
            <legend className="text-base font-medium text-gray-900">
              {formatMessage(messages.Alignment)}
            </legend>
          </div>
          <div className="mt-2 space-y-2">
            <div className="flex items-center">
              <input
                id="alignleft"
                name="align"
                value="left"
                type="radio"
                checked={alignment === 'left'}
                onChange={(e) => setAlignment(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="alignleft"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Left)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="aligncenter"
                name="align"
                value="center"
                type="radio"
                checked={alignment === 'center'}
                onChange={(e) => setAlignment(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="aligncenter"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Center)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="alignright"
                name="align"
                value="right"
                type="radio"
                checked={alignment === 'right'}
                onChange={(e) => setAlignment(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="alignright"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Right)}
              </label>
            </div>
          </div>
        </fieldset>
        <fieldset className="mt-3 col-span-1">
          <div>
            <legend className="text-base font-medium text-gray-900">
              {formatMessage(messages.VerticalAlignment)}
            </legend>
          </div>
          <div className="mt-2 space-y-2">
            <div className="flex items-center">
              <input
                id="verticaloff"
                name="vertical"
                value="off"
                type="radio"
                checked={vertical === 'off'}
                onChange={(e) => setVertical(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="vercitaloff"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Off)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="verticaltop"
                name="vertical"
                value="top"
                type="radio"
                checked={vertical === 'top'}
                onChange={(e) => setVertical(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="vercitaltop"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Top)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="verticalcenter"
                name="vertical"
                value="center"
                type="radio"
                checked={vertical === 'center'}
                onChange={(e) => setVertical(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="aligncenter"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Center)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="verticalbottom"
                name="vertical"
                value="bottom"
                type="radio"
                checked={vertical === 'bottom'}
                onChange={(e) => setVertical(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="verticalbottom"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Bottom)}
              </label>
            </div>
          </div>
        </fieldset>
        <fieldset className="mt-3 col-span-1">
          <div>
            <legend className="text-base font-medium text-gray-900">
              {formatMessage(messages.ResultFit)}
            </legend>
          </div>
          <div className="mt-2 space-y-2">
            <div className="flex items-center">
              <input
                id="rfoff"
                name="resultfit"
                value="off"
                type="radio"
                checked={resultfit === 'off'}
                onChange={(e) => setResultFit(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="alignleft"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Off)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="rfsc"
                name="resultfit"
                value="sc"
                type="radio"
                checked={resultfit === 'sc'}
                onChange={(e) => setResultFit(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="aligncenter"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.ResultImageCrop)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="rffit"
                name="resultfit"
                value="fit"
                type="radio"
                checked={resultfit === 'fit'}
                onChange={(e) => setResultFit(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="alignright"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.Fit)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="rfvf"
                name="resultfit"
                value="vf"
                type="radio"
                checked={resultfit === 'vf'}
                onChange={(e) => setResultFit(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="aligncenter"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {formatMessage(messages.VerticalFit)}
              </label>
            </div>
          </div>
        </fieldset>
        <fieldset className="mt-3 col-span-2">
          <div>
            <legend className="text-base font-medium text-gray-900">
              {formatMessage(messages.ResultUrl)}
            </legend>
          </div>
          <div className="mt-2 space-y-2">
            <div className="flex items-center">
              <input
                id="self"
                name="openLinks"
                value="_self"
                type="radio"
                checked={openLinks === '_self'}
                onChange={(e) => setOpenLinks(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="alignleft"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {'Self'}
              </label>
              <label
                htmlFor="alignleft"
                className="ml-3 block text-xs font-normal text-gray-500"
              >
                {formatMessage(messages.SelfDesc)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="blank"
                name="openLinks"
                value="_blank"
                type="radio"
                checked={openLinks === '_blank'}
                onChange={(e) => setOpenLinks(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="aligncenter"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {'Blank'}
              </label>
              <label
                htmlFor="alignleft"
                className="ml-3 block text-xs font-normal text-gray-500"
              >
                {formatMessage(messages.BlankDesc)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="parent"
                name="openLinks"
                value="_parent"
                type="radio"
                checked={openLinks === '_parent'}
                onChange={(e) => setOpenLinks(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="alignright"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {'Parent'}
              </label>
              <label
                htmlFor="alignleft"
                className="ml-3 block text-xs font-normal text-gray-500"
              >
                {formatMessage(messages.ParentDesc)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="top"
                name="openLinks"
                value="_top"
                type="radio"
                checked={openLinks === '_top'}
                onChange={(e) => setOpenLinks(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="aligncenter"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {'Top'}
              </label>
              <label
                htmlFor="alignleft"
                className="ml-3 block text-xs font-normal text-gray-500"
              >
                {formatMessage(messages.TopDesc)}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="none"
                name="openLinks"
                value="none"
                type="radio"
                checked={openLinks === 'none'}
                onChange={(e) => setOpenLinks(e.target.value)}
                className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
              />
              <label
                htmlFor="aligncenter"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {'None'}
              </label>
            </div>
          </div>
        </fieldset>
        <fieldset className="mt-3 col-span-2">
          <div>
            <legend className="text-base font-medium text-gray-900">
              {formatMessage(messages.Language)}
            </legend>
          </div>
          <div className="mt-2 space-y-2 grid grid-cols-2">
            {langOptions.map((l, i) => (
              <div className="flex items-center" key={i}>
                <input
                  id={l.value}
                  name="lang"
                  value={l.value}
                  type="radio"
                  checked={lang === l.value}
                  onChange={(e) => setLang(e.target.value)}
                  className="focus:ring-poltio-blue-500 h-4 w-4 text-poltio-blue-600 border-gray-300"
                />
                <label
                  htmlFor={l.value}
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  {formatMessage(messages[l.name])}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>

      <div className="mb-2 mt-3">
        <label
          htmlFor="utm_source"
          className="block text-sm font-medium text-gray-700"
        >
          {formatMessage(messages.UtmSource)}
        </label>
        <input
          type="text"
          name="utm_source"
          id="utm_source"
          autoComplete="new-password"
          className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="utm source"
          value={utmSource}
          onChange={(e) => setUtmSource(e.target.value)}
        />
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {formatMessage(messages.UtmDesc)}
        </p>
      </div>
      <div className="my-2">
        <label
          htmlFor="utm_medium"
          className="block text-sm font-medium text-gray-700"
        >
          {formatMessage(messages.UtmMedium)}
        </label>
        <input
          type="text"
          name="utm_medium"
          id="utm_medium"
          autoComplete="new-password"
          className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="utm medium"
          value={utmMedium}
          onChange={(e) => setUtmMedium(e.target.value)}
        />
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {formatMessage(messages.UtmMDesc)}
        </p>
      </div>
      <div className="my-2">
        <label
          htmlFor="utm_campaign"
          className="block text-sm font-medium text-gray-700"
        >
          {formatMessage(messages.UtmCampaign)}
        </label>
        <input
          type="text"
          name="utm_campaign"
          id="utm_campaign"
          autoComplete="new-password"
          className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="utm campaign"
          value={utmCampaign}
          onChange={(e) => setUtmCampaign(e.target.value)}
        />
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {formatMessage(messages.UtmCDesc)}
        </p>
      </div>

      <div className="my-2">
        <label
          htmlFor="puid"
          className="block text-sm font-medium text-gray-700"
        >
          PUID
        </label>
        <input
          type="text"
          name="puid"
          id="puid"
          className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="puid"
          value={puid}
          onChange={(e) => setPUID(e.target.value)}
        />
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {formatMessage(messages.PuidDesc)}
          <a
            className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
            href="https://platform.poltio.com/docs/options/#puid"
            target="_blank"
            rel="noreferrer"
          >
            {formatMessage(messages.Docs)}
          </a>
        </p>
      </div>
      <div className="my-2">
        <label
          htmlFor="custom_id"
          className="block text-sm font-medium text-gray-700"
        >
          {formatMessage(messages.CustomID)}
        </label>
        <input
          type="text"
          name="custom_id"
          id="custom_id"
          className="shadow-sm focus:ring-poltio-blue-500 focus:border-poltio-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="custom id"
          value={custom_id}
          onChange={(e) => setCustom_id(e.target.value)}
        />
        <p className="mt-2 text-sm text-gray-500" id="email-description">
          {formatMessage(messages.CustomIdDesc)}
          <a
            className="text-poltio-blue after:content-['_↗'] hover:text-sky-400"
            href="https://platform.poltio.com/docs/options/#custom-id"
            target="_blank"
            rel="noreferrer"
          >
            {formatMessage(messages.Docs)}
          </a>
        </p>
      </div>
      {modal ? (
        <div className="mt-2 w-1/2">
          <label
            htmlFor="custom_id"
            className="block text-sm font-medium text-gray-700"
          >
            {formatMessage(messages.SelectDomain)}
          </label>
          <Dropdown
            data={urlList}
            selectedIdx={selectedIdx}
            onChange={(i) => {
              setSelectedIdx(i);
              setLink(urlList[i]);
            }}
          />
        </div>
      ) : null}
      <div className="my-2">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <textarea
            id="embedcode"
            name="embedcode"
            rows={7}
            className="shadow-sm block w-full focus:ring-poltio-blue-500 focus:border-poltio-blue-500 sm:text-sm border border-gray-300 rounded-md"
            value={finalCode}
            readOnly
          />
        </div>
      </div>
      {buttons ? (
        <div className="mt-5 sm:mt-6 flex justify-center gap-4">
          <Button.Primary
            type="button"
            className="w-full"
            onClick={saveSettings}
            disabled={saving}
          >
            {formatMessage(messages.SaveSettings)}
          </Button.Primary>
          <Button.Primary
            type="button"
            className="w-full"
            onClick={copyEmbedCode}
          >
            {formatMessage(messages.CopyCode)}
          </Button.Primary>
          <Button.Primary
            type="button"
            className="w-full"
            onClick={() => (setOpen ? setOpen(false) : null)}
          >
            {formatMessage(messages.Close)}
          </Button.Primary>
        </div>
      ) : null}
    </>
  );
};

export default EmbedSettings;
