import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const FlyingPreview = () => {
  let [searchParams] = useSearchParams();

  useEffect(() => {
    setTimeout(() => {
      window.poltio.processFloatingWidgets();
    }, 500);
  }, []);

  return (
    <div>
      <div
        className="poltio-widget-flying"
        data-poltio-widget-content={
          searchParams.get('content') ?? 'd627477e-2dda-40ed-90e5-5259803d5c96'
        }
        data-poltio-widget-puid=""
        data-poltio-widget-loc={searchParams.get('loc') ?? 'en'}
        data-poltio-widget-disclaimer={searchParams.get('disclaimer') ?? 'off'}
        data-poltio-widget-resultfit={searchParams.get('resultfit') ?? 'vf'}
        data-poltio-widget-bgcolor={searchParams.get('widgetbg') ?? 'white'}
        data-poltio-floating-bgcolor={
          searchParams.get('floatingbg') ?? '#009eec'
        }
        data-poltio-floating-textcolor={
          searchParams.get('floatingtext') ?? 'white'
        }
        data-poltio-flying-icon-color={
          searchParams.get('iconcolor') ?? '#1E3D54'
        }
        data-poltio-flying-widget-icon-color={
          searchParams.get('widgeticoncolor') ?? '#1E3D54'
        }
        data-poltio-floating-title={
          searchParams.get('floatingtitle') ?? "Let's choose together"
        }
        data-poltio-floating-desc={
          searchParams.get('floatingdesc') ??
          'Not sure yet? Don’t worry! With a few simple questions we will guide you through the selection'
        }
        data-poltio-floating-buttontext={
          searchParams.get('floatingbuttontext') ?? 'Start Now'
        }
        data-poltio-floating-zindex={
          searchParams.get('floatingzindex') ?? '100'
        }
        data-poltio-floating-hide-button={
          searchParams.get('floatinghidebutton') ?? 'false'
        }
        data-poltio-floating-position={
          searchParams.get('floatingposition') ?? 'bottom-right'
        }
      ></div>
    </div>
  );
};

export default FlyingPreview;
